import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Table from '../components/Table'
import ProcedureListing from '../components/ProcedureListing'

export default function InspectionTestProcedureTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <h1 className="title is-1">Overview</h1>
      <p>
        This procedure is an incoming inspection document used to determine the
        acceptability of the following part(s):
      </p>
      <Table table={frontmatter.partAcceptabilityTable} />
      <p>
        All inspections should be conducted while wearing gloves in a clean,
        designated inspection area. Inspected product should be placed in
        “Rejected” or “Accepted” containers respectively.
      </p>
      <p>
        If this is a sample inspection, ensure that samples are pulled from
        throughout the lot (i.e. different boxes and different locations within
        each box).
      </p>
      <h1 className="title is-1">Sampling</h1>
      <p>
        <strong>
          All incoming product are to be inspected based on the chart(s) below.
        </strong>
        The AQL Level(s) and defect severities for this inspection are chosen in
        the Quality Control Plan (Doc ID Enter Doc ID.) and are based on
        pre-determined values specified in the Internal Quality Assurance
        Release Procedure (Doc ID 13512). The sample sizes and acceptability
        charts are generated from Standard ANSI/ASQ Z1.4-2007 (Doc ID 13514).
      </p>
      <p>
        After all the inspections have been completed, count the amount of
        rejected parts of each inspection and record the results in the
        inspection form (Doc ID 11784).
      </p>
      <p>
        If any inspection exceeds the maximum allowable rejects, Quarantine the
        lot and complete an NCR form (Doc ID 10823). Failed inspections will be
        addressed according to section 5.6 Disposition defined in Internal
        Quality Assurance Release Procedure (Doc ID 13512).
      </p>
      {frontmatter.isSpecialReducedInspection === 'true' ? (
        <SpecialInspection />
      ) : (
        <div />
      )}
      <h1 className="title is-1">General Inspections</h1>
      {frontmatter.hasCriticalDefects === 'true' ? (
        <div>
          <h2 className="title is-2">Critical Defects</h2>
          Collect the correct number of samples using the following table:
          <div style={{ height: '260px', width: '400px', background: 'white' }}>
            {frontmatter.isSpecialReducedInspection === 'true' ? (
              <StaticImage
                src="../images/Inspection Procedures/SpecialInspection_CriticalDefects.png"
                alt="SpecialInspection_CriticalDefects"
                width={400}
              />
            ) : (
              <StaticImage
                src="../images/Inspection Procedures/GeneralInspection_CriticalDefects.png"
                alt="GeneralInspection_CriticalDefects"
                width={400}
              />
            )}
          </div>
          <ProcedureListing procedure={frontmatter.criticalDefectsProcedure} />
        </div>
      ) : (
        <div></div>
      )}
      {frontmatter.hasMajorDefects === 'true' ? (
        <div>
          <h2 className="title is-2">Major Defects</h2>
          Collect the correct number of samples using the following table:
          <div style={{ height: '260px', width: '400px', background: 'white' }}>
            {frontmatter.isSpecialReducedInspection === 'true' ? (
              <StaticImage
                src="../images/Inspection Procedures/SpecialInspection_MajorDefects.png"
                alt="SpecialInspection_MajorDefects"
                width={400}
              />
            ) : (
              <StaticImage
                src="../images/Inspection Procedures/GeneralInspection_MajorDefects.png"
                alt="GeneralInspection_MajorDefects"
                width={400}
              />
            )}
          </div>
          <ProcedureListing procedure={frontmatter.majorDefectsProcedure} />
        </div>
      ) : (
        <div></div>
      )}
      {frontmatter.hasMinorDefects === 'true' ? (
        <div>
          <h2 className="title is-2">Minor Defects</h2>
          Collect the correct number of samples using the following table:
          <div style={{ height: '260px', width: '400px', background: 'white' }}>
            {frontmatter.isSpecialReducedInspection === 'true' ? (
              <StaticImage
                src="../images/Inspection Procedures/SpecialInspection_MinorDefects.png"
                alt="SpecialInspection_MinorDefects.png"
                width={400}
              />
            ) : (
              <StaticImage
                src="../images/Inspection Procedures/GeneralInspection_MinorDefects.png"
                alt="GeneralInspection_MinorDefects"
                width={400}
              />
            )}
          </div>
          <ProcedureListing procedure={frontmatter.minorDefectsProcedure} />
        </div>
      ) : (
        <div></div>
      )}
      {frontmatter.hasAcceptableDefects === 'true' ? (
        <div>
          <h2 className="title is-1">Acceptable Defects</h2>
          The following defects are acceptable.
          <ProcedureListing
            procedure={frontmatter.acceptableDefectsProcedure}
          />
        </div>
      ) : (
        <div></div>
      )}
      <h1 id="section" className="title is-1">
        100% Inspection
      </h1>
      <p>The following criterea need to be 100% inspected:</p>
      <ProcedureListing
        procedure={frontmatter.oneHundredPercentInspectedProcedure}
      />
    </Layout>
  )
}

function SpecialInspection() {
  return (
    <div>
      <h1 className="title is-1">Special Inspections</h1>
      <p>
        Some of the inspections required for this product are destructive and/or
        time consuming so the inspection level for the following inspections has
        been reduced. Use the charts below to determine the number of samples
        required for each test. Samples pulled for the general inspections above
        can be reused for special inspections.
      </p>
    </div>
  )
}

export const query = graphql`
  query MarkdownQueryInspectionProcedure($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        partAcceptabilityTable {
          partNumber
          description
          image
        }
        isSpecialReducedInspection
        hasCriticalDefects
        hasMajorDefects
        hasMinorDefects
        hasAcceptableDefects
        criticalDefectsProcedure {
          procedureDescription
          steps {
            stepDescription
            images {
              imagePath
              caption
            }
          }
        }
        majorDefectsProcedure {
          procedureDescription
          steps {
            stepDescription
            images {
              imagePath
              caption
            }
          }
        }
        minorDefectsProcedure {
          procedureDescription
          steps {
            stepDescription
            images {
              imagePath
              caption
            }
          }
        }
        acceptableDefectsProcedure {
          procedureDescription
          steps {
            stepDescription
            images {
              imagePath
              caption
            }
          }
        }
        oneHundredPercentInspectedProcedure {
          procedureDescription
          steps {
            stepDescription
            images {
              imagePath
              caption
            }
          }
        }
      }
    }
  }
`
