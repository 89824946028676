import React from 'react'
import './all.sass'
import Image from '../components/Image'

const ProcedureListing = function ({ procedure }) {
  return (
    <div>
      {procedure.map((subProcedure) => (
        <div className="container p-3">
          <h3 className="title is-3">{subProcedure.procedureDescription}</h3>
          {subProcedure.steps.map((step) => (
            <div className="content">
              <p>{step.stepDescription}</p>
              <div className="columns is-align-items-flex-start">
                {step.images &&
                  step.images.map((image) => (
                    <div
                      className={`column is-${(
                        12 / step.images.length
                      ).toString()}`}
                    >
                      {image.imagePath !== '' && (
                        <Image
                          filepath={image.imagePath}
                          caption={image.caption}
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ProcedureListing
